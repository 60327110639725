import { AddressData, AddressFormValuesModel } from '../models/address-form.models';
import { buildLinesForAddress, manualAddressToDATA_AddressAdapter } from './address-form-to-data-address.adapter';

const buildSingleLineFromAddressLines = (lines: string[]): string => {
  lines.unshift(lines.pop()!); // string Address line starts with postcode
  return lines.join(', ');
};

const getStringFromPickedAddress = (pickedAddress: AddressData | string | null): string => {
  if (!pickedAddress) return '';
  if (typeof pickedAddress == 'string') return pickedAddress;
  if (!pickedAddress.address) return '';

  const { lines } = buildLinesForAddress(pickedAddress.address);
  return buildSingleLineFromAddressLines(lines);
};

const getStringFromManualAddress = (formAddressData: AddressFormValuesModel): string => {
  const { lines } = buildLinesForAddress(manualAddressToDATA_AddressAdapter(formAddressData));
  return buildSingleLineFromAddressLines(lines);
};

export const addressFormToStringAdapter = (formAddressData: AddressFormValuesModel): string => {
  if (formAddressData.isManual) return getStringFromManualAddress(formAddressData);
  return getStringFromPickedAddress(formAddressData.pickedAddress);
};
