// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// source: shared/api/common/ENUM_ImportType.proto

export enum ENUM_ImportType {
  IMPORT_TYPE_UNSPECIFIED = 'IMPORT_TYPE_UNSPECIFIED',
  IMPORT_TYPE_CLIENT_USER_IMPORT = 'IMPORT_TYPE_CLIENT_USER_IMPORT',
  IMPORT_TYPE_DATA_IMPORT = 'IMPORT_TYPE_DATA_IMPORT',
  IMPORT_TYPE_MORTGAGE_ADJUSTMENT = 'IMPORT_TYPE_MORTGAGE_ADJUSTMENT',
  IMPORT_TYPE_MORTGAGE_REWRITE = 'IMPORT_TYPE_MORTGAGE_REWRITE',
  IMPORT_TYPE_MORTGAGE_CLOSURE = 'IMPORT_TYPE_MORTGAGE_CLOSURE',
  IMPORT_TYPE_MORTGAGE_PRE_COMPLETION = 'IMPORT_TYPE_MORTGAGE_PRE_COMPLETION',
  UNRECOGNIZED = 'UNRECOGNIZED',
}
