export interface UserTableRow {
  id: string;
  fullName: string;
  lastSignIn: string;
  companyName: string;
  companyId: string;
  email: string;
  clientCount: number;
  hasActionInProgress: boolean;
}

export enum UserSortKey {
  BY_COMPANY = 'byCompany',
  BY_FULL_NAME = 'byFullName',
  BY_EMAIL = 'byEmail',
  BY_LAST_SIGN_IN = 'byLastSignIn',
}
