import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { environment } from '@environments/environment.dashlydev';
import { init as initFullStory } from '@fullstory/browser';
import { Store } from '@ngrx/store';
import { SharedToastComponent } from '@shared-lib/components/toast/shared-toast.component';
import { setInitialPlatformType } from '@shared/store/platform/platform.actions';
import { Subject } from 'rxjs';
import { AuthService } from './shared/services/auth.service';

@Component({
  selector: 'ap-root',
  templateUrl: './app.component.html',
  imports: [RouterOutlet, SharedToastComponent],
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly store = inject(Store);

  private readonly unsubscribe$ = new Subject<void>();

  constructor(private readonly authService: AuthService) {
    this.initFullStory();
  }

  ngOnInit(): void {
    if (!environment.production) {
      this.store.dispatch(setInitialPlatformType());
    }
    this.authService.initAuthData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initFullStory(): void {
    initFullStory({
      orgId: '9ZEQS',
      devMode: !environment.production,
    });
  }
}
