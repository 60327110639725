import { Action, createReducer, on } from '@ngrx/store';
import { AuthState } from '../../models/app-state.model';
import { authError, loginAction, loginFailureAction, loginSuccessAction, resetPasswordResetDoneAction } from './auth.action';

const initialState: AuthState = {
  errors: null,
  loading: false,
  token: null,
  metadata: {
    resetRequestDone: false,
    authErrorCode: null,
  },
};

const reducer = createReducer(
  initialState,

  on(loginAction, (state) => ({
    ...state,
    loading: true,
  })),

  on(loginSuccessAction, (state, { token, uid }) => ({
    ...state,
    userId: uid,
    token: token,
    loading: false,
  })),

  on(loginFailureAction, (state, { error }) => ({
    ...state,
    loading: false,
    userId: null,
    token: null,
    errors: error,
  })),

  on(resetPasswordResetDoneAction, (state) => ({
    ...state,
    metadata: {
      ...state.metadata,
      resetRequestDone: false,
    },
  })),

  on(
    authError,
    (state, { authErrorCode }): AuthState => ({
      ...state,
      userId: null,
      token: null,
      loading: false,
      metadata: {
        ...state.metadata,
        authErrorCode,
      },
    }),
  ),
);

export function authReducer(state: AuthState = initialState, action: Action): AuthState {
  return reducer(state, action);
}
