import { EnvironmentModel } from '@shared/models/environment.model';
import { FeatureFlag } from '@shared/models/feature-config.model';

class EnvironmentImpl implements EnvironmentModel {
  production = false;
  sentryDSN = 'https://e8c4edee1f9c4bfb959ec3031090a777@sentry.io/1471235';

  featureConfig = {
    [FeatureFlag.LANDCLAN_ADDRESSES]: false,
    [FeatureFlag.CREDIT_REPORT_CLIENT_TABS]: true,
    [FeatureFlag.CREDIT_REPORT_SETTINGS]: true,
    [FeatureFlag.PRE_COMPLETIONS]: true,
    [FeatureFlag.GLOBAL_SEARCH]: true,
  };

  googleCloudPlatformApiKey = 'AIzaSyCEw-HdVyu1zDW9oLjM56yuTeyY3EvTeYU';
  urls = {
    clientPortalUrl: 'https://my.dashly-dev.com',
    adviserPortalUrl: 'https://port.dashly-dev.com',
    universalLeadCaptureUrl: 'https://welcome.dashly-dev.com',
    apiUrl: 'https://api.dashly-dev.com',
    dashlyIQUrl: 'https://iq.dashly-dev.com',
  };
}

export const environment = new EnvironmentImpl();
