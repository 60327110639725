import { ENUM_AdvisorUserPermissionLevel } from '@api-new/common';
import { HTTP_AP_ListReferrals_Request_Filter, HTTP_AP_ListReferrals_Request_Sort } from '@api-new/userservice';
import { ReferralCodeTableRow } from '@platform/referral-code/models/referral-code-table.model';
import { HTTP_ListReferrals_Request_SortKeys } from '@platform/referral-code/utils/SortKeys.enum';
import { RowDefinitionType, TableRowDefinitionModel } from '@shared/components/table';
import { Permission } from '@shared/enums';
import { HasCurrentUserRequiredPermissionsFunction } from '@shared/services/permission.service';

export const referralCodeTableFilterDefaults: HTTP_AP_ListReferrals_Request_Filter = {
  codeContains: '',
  assignToAdvisorUserIds: null,
  assignToCompanyIds: null,
  referredByAffiliateIds: null,
  nameOrDescription: '',
  createdAt: null,
};

export const getReferralCodeTableDefinition = (
  hasCurrentUserRequiredPermissions: HasCurrentUserRequiredPermissionsFunction,
): TableRowDefinitionModel<ReferralCodeTableRow, HTTP_AP_ListReferrals_Request_Sort>[] => {
  return [
    {
      columnLabel: 'Name',
      sortKey: HTTP_ListReferrals_Request_SortKeys.BY_NAME,
      columnKey: 'name',
      type: RowDefinitionType.TEXT,
      width: {
        min: '240px',
        max: '240px',
      },
      isFrozen: true,
    },
    {
      columnLabel: 'Company',
      sortKey: HTTP_ListReferrals_Request_SortKeys.BY_COMPANY,
      columnKey: 'companyName',
      type: RowDefinitionType.TEXT,
      isHidden: !hasCurrentUserRequiredPermissions([
        {
          permission: Permission.REFERRAL_READ,
          permissionLevel: ENUM_AdvisorUserPermissionLevel.ADVISOR_USER_PERMISSION_LEVEL_COMPANY,
        },
      ]),
      width: {
        min: '200px',
        max: '200px',
      },
    },
    {
      columnLabel: 'Adviser',
      sortKey: HTTP_ListReferrals_Request_SortKeys.BY_ADVISER,
      columnKey: 'adviserName',
      type: RowDefinitionType.TEXT,
      isHidden: !hasCurrentUserRequiredPermissions([
        {
          permission: Permission.REFERRAL_READ,
          permissionLevel: ENUM_AdvisorUserPermissionLevel.ADVISOR_USER_PERMISSION_LEVEL_DIRECT,
        },
      ]),
      width: {
        min: '120px',
        max: '120px',
      },
    },
    {
      columnLabel: 'Affiliate',
      sortKey: HTTP_ListReferrals_Request_SortKeys.BY_AFFILIATE,
      columnKey: 'affiliateName',
      type: RowDefinitionType.TEXT,
      width: {
        min: '120px',
        max: '120px',
      },
    },
    {
      columnLabel: 'Created',
      columnKey: 'createdAt',
      sortKey: HTTP_ListReferrals_Request_SortKeys.BY_CREATED_AT,
      type: RowDefinitionType.DATE,
      width: {
        min: '100px',
        max: '100px',
      },
    },
    {
      columnLabel: 'Clicks',
      columnKey: 'referralClicksCount',
      sortKey: 'byClicksCount',
      type: RowDefinitionType.NUMBER,
      width: {
        min: '70px',
        max: '70px',
      },
    },
    {
      columnLabel: 'Status',
      columnKey: 'isActive',
      type: RowDefinitionType.BINARY_STATUS,
      width: {
        min: '120px',
        max: '120px',
      },
    },
    {
      columnLabel: 'Code',
      columnKey: 'code',
      type: RowDefinitionType.COPY,
      width: {
        min: '250px',
        max: '250px',
      },
    },
  ];
};
