import { Pipe, PipeTransform } from '@angular/core';
import { booleanToYesNo } from '../utils/booleanToYesNo';

@Pipe({
  name: 'booleanToYesOrNo',
  pure: true,
  standalone: true,
})
export class BooleanToYesOrNoPipe implements PipeTransform {
  transform(booleanToTransform: boolean, nullable = false): string {
    if (nullable && booleanToTransform == null) {
      return '-';
    }

    return booleanToYesNo(booleanToTransform);
  }
}
