import { FormControl, FormGroup } from '@angular/forms';
import { ENUM_AdvisorUserPermissionLevel, ENUM_CaseType, ENUM_NullableBool } from '@api-new/common';
import {
  HTTP_AP_ListClientUsers_ForTable_Request_Filter,
  HTTP_AP_ListClientUsers_ForTable_Request_Filter_ENUM_CreationMethod,
} from '@api-new/userservice';
import { HTTP_ListClientUsers_ForTable_Request_SortKeys } from '@platform/client/models/client-state.model';
import { ClientTableRow } from '@platform/client/models/client-table.model';
import { SelectInterface } from '@shared/components/form';
import { RowDefinitionType, TableRowDefinitionModel } from '@shared/components/table';
import { Permission, TimeIntervalEnum } from '@shared/enums';
import { ENUM_CaseTypeMap } from '@shared/maps';
import { HasCurrentUserRequiredPermissionsFunction } from '@shared/services/permission.service';

export enum ClientTableColumnKey {
  ID = 'clientUserId',
  CLIENT_USER_FULL_NAME = 'clientUserFullName',
  COMPANY_NAME = 'companyTradingName',
  ADVISOR_USER_FULL_NAME = 'advisorUserFullName',
  CLIENT_USER_CREATED_AT = 'clientUserCreatedAt',
  CLIENT_USER_CREATION_METHOD = 'clientUserCreationMethod',
  REFERRAL_CODE = 'referralCode',
  CLIENT_USER_LAST_SIGN_IN = 'clientUserLastSignIn',
  PROPERTY_COUNT = 'propertyCount',
  TOTAL_PROPERTY_SUM = 'sumOfPropertiesCurrentValueAsOwnershipShare',
  EQUITY = 'sumOfEquities',
  MORTGAGE_COUNT = 'mortgageCount',
  NOTIFICATION_SETTINGS = 'notificationSettings',
}

export const getClientRowsDefinition = (
  hasCurrentUserRequiredPermissions: HasCurrentUserRequiredPermissionsFunction,
): TableRowDefinitionModel<ClientTableRow>[] => [
  {
    columnLabel: 'Client name',
    type: RowDefinitionType.TEXT,
    columnKey: ClientTableColumnKey.CLIENT_USER_FULL_NAME,
    sortKey: HTTP_ListClientUsers_ForTable_Request_SortKeys.BY_CLIENT_USER_FULL_NAME,
    width: {
      min: '240px',
      max: '240px',
    },
    isFrozen: true,
  },
  {
    columnLabel: 'Company',
    type: RowDefinitionType.TEXT,
    columnKey: ClientTableColumnKey.COMPANY_NAME,
    sortKey: HTTP_ListClientUsers_ForTable_Request_SortKeys.BY_COMPANY_TRADING_NAME,
    isHidden: !hasCurrentUserRequiredPermissions([
      {
        permission: Permission.CLIENT_USER_READ,
        permissionLevel: ENUM_AdvisorUserPermissionLevel.ADVISOR_USER_PERMISSION_LEVEL_COMPANY,
      },
    ]),
    width: {
      min: '200px',
      max: '200px',
    },
  },
  {
    columnLabel: 'Assigned to',
    type: RowDefinitionType.TEXT,
    columnKey: ClientTableColumnKey.ADVISOR_USER_FULL_NAME,
    sortKey: HTTP_ListClientUsers_ForTable_Request_SortKeys.BY_ADVISOR_USER_FULL_NAME,
    isHidden: !hasCurrentUserRequiredPermissions([
      {
        permission: Permission.CLIENT_USER_READ,
        permissionLevel: ENUM_AdvisorUserPermissionLevel.ADVISOR_USER_PERMISSION_LEVEL_DIRECT,
      },
    ]),
    width: {
      min: '200px',
      max: '200px',
    },
  },
  {
    columnLabel: 'Created',
    type: RowDefinitionType.DATE_TIME_SINCE,
    columnKey: ClientTableColumnKey.CLIENT_USER_CREATED_AT,
    sortKey: HTTP_ListClientUsers_ForTable_Request_SortKeys.BY_CLIENT_USER_CREATED_AT,
    width: {
      min: '120px',
      max: '120px',
    },
  },
  {
    columnLabel: 'Source',
    type: RowDefinitionType.TEXT,
    columnKey: ClientTableColumnKey.CLIENT_USER_CREATION_METHOD,
    width: {
      min: '70px',
      max: '70px',
    },
  },
  {
    columnLabel: 'Ref. code',
    type: RowDefinitionType.TEXT,
    columnKey: ClientTableColumnKey.REFERRAL_CODE,
    width: {
      min: '120px',
      max: '120px',
    },
  },
  {
    columnLabel: 'Last login',
    type: RowDefinitionType.DATE_TIME_SINCE,
    columnKey: ClientTableColumnKey.CLIENT_USER_LAST_SIGN_IN,
    sortKey: HTTP_ListClientUsers_ForTable_Request_SortKeys.BY_CLIENT_USER_LAST_SIGN_IN,
    width: {
      min: '120px',
      max: '120px',
    },
  },
  {
    columnLabel: 'Properties',
    type: RowDefinitionType.TEXT,
    columnKey: ClientTableColumnKey.PROPERTY_COUNT,
    sortKey: HTTP_ListClientUsers_ForTable_Request_SortKeys.BY_PROPERTY_COUNT,
    width: {
      min: '100px',
      max: '100px',
    },
  },
  {
    columnLabel: 'Total properties value',
    type: RowDefinitionType.CURRENCY,
    columnKey: ClientTableColumnKey.TOTAL_PROPERTY_SUM,
    width: {
      min: '100px',
      max: '150px',
    },
  },
  {
    columnLabel: 'Equity',
    type: RowDefinitionType.CURRENCY,
    columnKey: ClientTableColumnKey.EQUITY,
    width: {
      min: '100px',
      max: '150px',
    },
  },

  {
    columnLabel: 'Mortgages',
    type: RowDefinitionType.TEXT,
    columnKey: ClientTableColumnKey.MORTGAGE_COUNT,
    sortKey: HTTP_ListClientUsers_ForTable_Request_SortKeys.BY_MORTGAGE_COUNT,
    width: {
      min: '50px',
      max: '100px',
    },
  },
  {
    columnLabel: 'Notifications',
    type: RowDefinitionType.NOTIFICATION_SETTINGS,
    columnKey: ClientTableColumnKey.NOTIFICATION_SETTINGS,
    width: {
      min: '125px',
      max: '125px',
    },
  },
];

export const NOTIFICATION_SETTINGS_OPTIONS: SelectInterface<ENUM_NullableBool>[] = [
  { label: 'Any', value: null },
  { label: 'On', value: ENUM_NullableBool.NULLABLE_BOOL_TRUE },
  { label: 'Off', value: ENUM_NullableBool.NULLABLE_BOOL_FALSE },
];

export const CASE_TYPE_OPTIONS: SelectInterface<ENUM_CaseType>[] = [
  { value: null, label: 'Any' },
  { value: ENUM_CaseType.CASE_TYPE_SMART_SEARCH, label: ENUM_CaseTypeMap.get(ENUM_CaseType.CASE_TYPE_SMART_SEARCH).text },
  { value: ENUM_CaseType.CASE_TYPE_MORTGAGE, label: ENUM_CaseTypeMap.get(ENUM_CaseType.CASE_TYPE_MORTGAGE).text },
  { value: ENUM_CaseType.CASE_TYPE_HELP, label: ENUM_CaseTypeMap.get(ENUM_CaseType.CASE_TYPE_HELP).text },
];

export const CLIENT_USER_CREATION_METHOD_OPTIONS: SelectInterface<HTTP_AP_ListClientUsers_ForTable_Request_Filter_ENUM_CreationMethod>[] = [
  {
    label: 'Any',
    value: null,
  },
  {
    label: 'Sign up',
    value: HTTP_AP_ListClientUsers_ForTable_Request_Filter_ENUM_CreationMethod.CREATION_METHOD_SIGN_UP,
  },
  {
    label: 'Adviser keyed',
    value: HTTP_AP_ListClientUsers_ForTable_Request_Filter_ENUM_CreationMethod.CREATION_METHOD_BY_ADVISOR,
  },
  {
    label: 'Imported',
    value: HTTP_AP_ListClientUsers_ForTable_Request_Filter_ENUM_CreationMethod.CREATION_METHOD_IMPORTED,
  },
];

export interface ClientTableFilterFormModel {
  clientUserFullNameOrEmail: FormControl<string>;
  companyIds: FormControl<string[]>;
  advisorUserFullNameOrEmail: FormControl<string>;
  referralCode: FormControl<string>;
  clientUserCreatedAt: FormControl<{ min: null; max: null } | TimeIntervalEnum>;
  clientUserLastSignIn: FormControl<{ min: null; max: null } | TimeIntervalEnum>;
  propertyCount: FormControl<{ min: null; max: null }>;
  mortgageCount: FormControl<{ min: null; max: null }>;
  betterDealAlertNotificationsEnabled: FormControl<ENUM_NullableBool>;
  homeReportNotificationsEnabled: FormControl<ENUM_NullableBool>;
  mortgageUpdateNotificationsEnabled: FormControl<ENUM_NullableBool>;
  creationMethod: FormControl<HTTP_AP_ListClientUsers_ForTable_Request_Filter_ENUM_CreationMethod[]>;
}

export const clientTableFilterDefaults: HTTP_AP_ListClientUsers_ForTable_Request_Filter = {
  clientUserFullNameOrEmail: '',
  advisorUserFullNameOrEmail: '',
  referralCode: '',
  clientUserCreatedAt: null,
  clientUserLastSignIn: null,
  propertyCount: { min: null, max: null },
  mortgageCount: { min: null, max: null },
  betterDealAlertNotificationsEnabled: null,
  homeReportNotificationsEnabled: null,
  mortgageUpdateNotificationsEnabled: null,
  companyIds: [],
  creationMethod: null,
};

export const buildClientTableFilterForm = (): FormGroup<ClientTableFilterFormModel> =>
  new FormGroup<ClientTableFilterFormModel>({
    clientUserFullNameOrEmail: new FormControl(''),
    advisorUserFullNameOrEmail: new FormControl(''),
    referralCode: new FormControl(''),
    clientUserCreatedAt: new FormControl(null),
    clientUserLastSignIn: new FormControl(null),
    propertyCount: new FormControl({ min: null, max: null }),
    mortgageCount: new FormControl({ min: null, max: null }),
    betterDealAlertNotificationsEnabled: new FormControl(null),
    homeReportNotificationsEnabled: new FormControl(null),
    mortgageUpdateNotificationsEnabled: new FormControl(null),
    companyIds: new FormControl(null),
    creationMethod: new FormControl(null),
  });
