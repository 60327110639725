import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { RoutePaths } from '@app/app.utils';
import { ToastService, ToastSeverity } from '@shared-lib/services/toast.service';
import { SignInApiErrorCode } from '@shared/enums';
import { EMPTY, Observable, Subject, throwError } from 'rxjs';
import { catchError, debounceTime } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { TOAST_MESSAGE } from '../utils';

const WHITE_LIST_401_MESSAGES = [SignInApiErrorCode.ADVISOR_SIGN_IN__EMAIL_NOT_FOUND];

@Injectable()
export class AuthErrorInterceptor implements HttpInterceptor {
  private router = inject(Router);
  private authService = inject(AuthService);
  private toastService = inject(ToastService);
  private error401Subject = new Subject<void>();
  private error401$ = this.error401Subject.asObservable().pipe(debounceTime(200));

  constructor() {
    this.error401$.subscribe(() => {
      this.authService.logout();
      this.router.navigate([RoutePaths.Landing, RoutePaths.Login]);
      this.toastService.showToast(ToastSeverity.error, TOAST_MESSAGE.ERROR.unauthorized);
    });
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (WHITE_LIST_401_MESSAGES.includes(error.error?.message)) {
          return throwError(() => error);
        }
        if (error.status === 401) {
          this.authService.logout();
          this.error401Subject.next();
          return EMPTY;
        }
        return throwError(() => error);
      }),
    );
  }
}
