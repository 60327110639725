/* eslint-disable */
export { booleanToYesNo } from '@shared-lib/utils/booleanToYesNo';
export * as calculateListScroll from './calculateListScroll';
export { companyIdentifier } from './companyIdentifier';
export { convertToStringArray } from './convertToStringArray';
export { dateToFormat } from './dateUtils/dateToFormat';
export { formatMomentToISO } from './dateUtils/formatMomentToISO';
export { formatSecondsToMinutesAndHours } from './dateUtils/formatSecondsToMinutesAndHours';
export { getDate } from './dateUtils/getDate';
export { getFormattedDuration } from './dateUtils/getFormattedDuration';
export { getFormattedDurationMonths } from './dateUtils/getFormattedDurationMonths';
export { momentify } from './dateUtils/momentify';
export { getPagedList } from './getPagedList';
export { getTotalPages } from './getTotalPages';
export { decimalToPercentage, percentageToDecimal } from '@shared-lib/utils/percentageVsDecimal';
export { remapEntityToOptions } from './remapEntityToOptions';
export { removeNullValuesFromObject } from './removeNullValuesFromObject';
export { replaceVariablesInString } from './replaceVariablesInString';
export { standardizeUrlForHref } from './standardizeUrlForHref';
export { ControlsOf } from './typedFormUtils';
export { capitalize, upperCaseFirstLetter } from './upperCaseFirstLetter';
export { monthsToYears, yearsToMonths } from './dateUtils/yearsVsMonths';
export { extractFileNameFromUrl } from './extractFileNameFromUrl';
export { getFullName } from './getFullName';
export { getHelloMessageByTimeOfDay } from './getHelloMessageByTimeOfDay';
export { passwordMatchValidator } from './passwordMatchValidator';
export { TableFilterUtils } from './TableFilterUtils';
export { setQueryParams } from './setQueryParams';
export { mapStringParamsToCorrectTypes } from './mapStringParamsToCorrectTypes';
export { scrollViewToElement } from './scrollViewToElement';
export { TOAST_MESSAGE } from './toastMessages';
export { addMonthsToDate, addDaysToDate } from './dateUtils/addMonthsToDate';
export { monthDiff } from './dateUtils/monthDiff';
export { monthsToYearsInWholeNumbers } from './dateUtils/yearsVsMonths';

export const isNullOrUndefined = (data) => {
  return data === null || data === undefined;
};
