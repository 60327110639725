import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { ENUM_AdvisorUserPermissionLevel } from '@api-new/common';
import { HTTP_AP_AdvisorUserPermissionSettings } from '@api-new/userservice';
import { RoutePaths } from '@app/app.utils';
import { Store } from '@ngrx/store';
import { getNestedValue } from '@shared-lib/utils/getNestedValue';
import { Permission } from '@shared/enums';
import { ENUM_AdvisorUserPermissionLevelMap } from '@shared/maps';
import { selectUserPermissions } from '@shared/store/user/user.selectors';

export interface PermissionDefinition {
  permission: Permission;
  permissionLevel?: ENUM_AdvisorUserPermissionLevel;
}

export type HasCurrentUserRequiredPermissionsFunction = (permissionDefinitions: PermissionDefinition[]) => boolean;

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  protected readonly router = inject(Router);
  protected readonly store = inject(Store);

  userPermissions: HTTP_AP_AdvisorUserPermissionSettings;

  constructor() {
    this.store.select(selectUserPermissions).subscribe((userPermissions) => {
      this.userPermissions = userPermissions;
    });
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const routePermissions = route.data.permissions;

    if (this.hasCurrentUserRequiredPermissions(routePermissions)) {
      return true;
    }
    void this.router.navigate([RoutePaths.Platform, RoutePaths.Dashboard]);

    return false;
  }

  hasCurrentUserRequiredPermissions(permissions: PermissionDefinition[], hasOneOfPermissions?: boolean): boolean {
    const hasRequiredPermissions = permissions?.map(({ permission, permissionLevel }) =>
      this.hasCurrentUserPermissionOnLevel(permission, permissionLevel),
    );
    if (hasOneOfPermissions) {
      if (hasRequiredPermissions?.includes(true)) {
        return true;
      }
    } else {
      if (!hasRequiredPermissions?.includes(false)) {
        return true;
      }
    }
    return false;
  }

  private hasCurrentUserPermissionOnLevel(permission: Permission, permissionLevel?: ENUM_AdvisorUserPermissionLevel): boolean {
    const adviserUserPermissionLevel = getNestedValue(this.userPermissions, permission);
    if (!adviserUserPermissionLevel) {
      return false;
    }
    if (permissionLevel === undefined) {
      return true;
    }
    return (
      ENUM_AdvisorUserPermissionLevelMap.get(adviserUserPermissionLevel).value >=
      ENUM_AdvisorUserPermissionLevelMap.get(permissionLevel).value
    );
  }
}

export const PermissionGuard: CanActivateFn = (next: ActivatedRouteSnapshot): boolean => {
  return inject(PermissionService).canActivate(next);
};
