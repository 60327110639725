// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// source: shared/api/common/ENUM_MortgageExpiryType.proto

export enum ENUM_MortgageExpiryType {
  MORTGAGE_EXPIRY_TYPE_UNSPECIFIED = 'MORTGAGE_EXPIRY_TYPE_UNSPECIFIED',
  MORTGAGE_EXPIRY_TYPE_EARLY = 'MORTGAGE_EXPIRY_TYPE_EARLY',
  MORTGAGE_EXPIRY_TYPE_PRE_EXPIRING = 'MORTGAGE_EXPIRY_TYPE_PRE_EXPIRING',
  MORTGAGE_EXPIRY_TYPE_EXPIRING = 'MORTGAGE_EXPIRY_TYPE_EXPIRING',
  MORTGAGE_EXPIRY_TYPE_EXPIRED = 'MORTGAGE_EXPIRY_TYPE_EXPIRED',
  UNRECOGNIZED = 'UNRECOGNIZED',
}
