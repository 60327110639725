import { Pipe, PipeTransform } from '@angular/core';
import { getFormattedDuration } from '@shared/utils';
import moment from 'moment';

@Pipe({
  name: 'formattedDurationMonths',
  standalone: true,
})
export class FormattedDurationMonthsPipe implements PipeTransform {
  transform(durationMonths: number, short: boolean = false): string {
    if (durationMonths == null || typeof durationMonths !== 'number' || durationMonths < 1) {
      return null;
    }

    const duration = moment.duration(moment().add(durationMonths, 'months').diff(moment()));

    return getFormattedDuration(duration, short);
  }
}
