import { createSelector } from '@ngrx/store';
import { AppStateModel } from '@shared/models/app-state.model';
import { selectUserCompanyId } from '@shared/store/user/user.selectors';
import { CompanyState } from '../models/company-state.model';
import { companiesAdapter } from './company.reducer';

const { selectEntities: selectAllCompanyDetailEntities } = companiesAdapter.getSelectors();

export const selectCompanyState = (state: AppStateModel): CompanyState => state.company;

export const selectCompanyDetailEntities = createSelector(selectCompanyState, (state: CompanyState) =>
  selectAllCompanyDetailEntities(state.companiesDetails),
);

export const selectIsLoadingCompany = createSelector(selectCompanyState, (state: CompanyState): boolean => state.loading);

export const selectIsCompanyLoaded = createSelector(selectCompanyState, (state: CompanyState): boolean => state.loaded);

export const selectCompanyLogo = createSelector(
  selectCompanyDetailEntities,
  selectUserCompanyId,
  (company, userCompanyId: string): string => company[userCompanyId]?.logo,
);

export const selectCompanyName = createSelector(
  selectCompanyDetailEntities,
  selectUserCompanyId,
  (company, userCompanyId: string): string => company[userCompanyId]?.tradingName,
);
