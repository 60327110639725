import { ENUM_CompanyIntegrationImportColumnsType } from '@api-new/importservice';

export const ENUM_CompanyIntegrationImportColumnsTypeMap = new Map([
  [ENUM_CompanyIntegrationImportColumnsType.COMPANY_INTEGRATION_IMPORT_COLUMNS_TYPE_UNSPECIFIED, 'Unspecified'],
  [ENUM_CompanyIntegrationImportColumnsType.COMPANY_INTEGRATION_IMPORT_COLUMNS_TYPE_CLEARSCORE, 'Clearscore'],
  [ENUM_CompanyIntegrationImportColumnsType.COMPANY_INTEGRATION_IMPORT_COLUMNS_TYPE_EXPERIAN, 'Experian'],
  [ENUM_CompanyIntegrationImportColumnsType.COMPANY_INTEGRATION_IMPORT_COLUMNS_TYPE_FLUENT_EXISTING_CUSTOMER, 'Fluent Existing Customer'],
  [ENUM_CompanyIntegrationImportColumnsType.COMPANY_INTEGRATION_IMPORT_COLUMNS_TYPE_MAB, 'MAB'],
  [ENUM_CompanyIntegrationImportColumnsType.UNRECOGNIZED, 'Unrecognized'],
]);
