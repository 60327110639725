export enum RowDefinitionType {
  TEXT = 'row-definition-text',
  FULLNAME_SHORT = 'row-definition-fullname-short',
  FULLNAME_INITIALS = 'row-definition-fullname-intials',
  BOOLEAN = 'row-definition-boolean',
  CURRENCY = 'row-definition-currency',
  PERCENT = 'row-definition-percent',
  NUMBER = 'row-definition-number',
  BINARY_STATUS = 'row-definition-binary-status',
  DATE = 'row-definition-date',
  COPY = 'row-definition-copy',
  IMAGE = 'row-definition-image',
  LABELED_IMG = 'row-definition-labeled-image',
  DATE_WITH_TIME = 'row-definition-date-with-time',
  DATE_TIME_SINCE = 'row-definition-date-time-since',
  ADDRESS = 'row-definition-address',
  PROPERTY_INVESTMENT_TYPE = 'property-investment-type',
  LINK = 'link',
  NOTIFICATION_SETTINGS = 'notification-settings',
  MORTGAGE_TYPE = 'mortgage-type',
  DURATION_MONTHS = 'duration-months',
}
