import { HTTP_AP_Company_ForDetails, HTTP_AP_ListCompanies_ForTable_Response_Company } from '@api-new/companyservice';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { CompanyState } from '../models/company-state.model';
import {
  createCompany,
  createCompanyFailure,
  createCompanySuccess,
  getCompanyById,
  getCompanyByIdFailure,
  getCompanyByIdSuccess,
} from './company.action';

export const companiesAdapter: EntityAdapter<HTTP_AP_Company_ForDetails> = createEntityAdapter<HTTP_AP_Company_ForDetails>({
  selectId: (company) => company.id,
});

export const companyListAdapter: EntityAdapter<HTTP_AP_ListCompanies_ForTable_Response_Company> =
  createEntityAdapter<HTTP_AP_ListCompanies_ForTable_Response_Company>({
    selectId: (company) => company.id,
  });

export const companiesInitialState: CompanyState = {
  companiesDetails: companiesAdapter.getInitialState(),
  companiesList: companyListAdapter.getInitialState(),
  errors: null,
  loading: false,
  loaded: false,
  loadingCompanyList: false,
  metadata: {
    userCompanyID: '',
    selectedCompanyDetailId: '',
  },
};

const reducer = createReducer(
  companiesInitialState,

  on(
    getCompanyById,
    (state): CompanyState => ({
      ...state,
      loading: !state.loaded,
      errors: null,
    }),
  ),

  on(getCompanyByIdSuccess, (state, { company }): CompanyState => {
    return {
      ...state,
      companiesDetails: companiesAdapter.addOne(company, state.companiesDetails),
      loading: false,
      loaded: true,
    };
  }),

  on(createCompany, (state): CompanyState => {
    return {
      ...state,
      loading: true,
    };
  }),

  on(createCompanySuccess, createCompanyFailure, (state): CompanyState => {
    return {
      ...state,
      loading: false,
    };
  }),

  on(
    getCompanyByIdFailure,
    (state, { error }): CompanyState => ({
      ...state,
      loading: false,
    }),
  ),
);

export function companyReducer(state: CompanyState = companiesInitialState, action: Action): CompanyState {
  return reducer(state, action);
}
