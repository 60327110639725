import { ENUM_AdvisorUserPermissionLevel } from '@api-new/common';
import { HTTP_AP_ListCompanies_ForTable_Request_Filter } from '@api-new/companyservice';
import { CompanyTableRow } from '@platform/company/models/company-table.model';
import { RowDefinitionType, TableRowDefinitionModel } from '@shared/components/table';
import { Permission } from '@shared/enums';
import { HasCurrentUserRequiredPermissionsFunction } from '@shared/services/permission.service';

export const companyTableFilterDefaults: HTTP_AP_ListCompanies_ForTable_Request_Filter = {
  tradingNameContains: '',
  networkId: null,
  betterDealAlertNotificationsEnabled: null,
  homeReportNotificationsEnabled: null,
  mortgageUpdateNotificationsEnabled: null,
};

export const companyTableHeaderDefinition = (
  hasCurrentUserRequiredPermissions: HasCurrentUserRequiredPermissionsFunction,
): TableRowDefinitionModel<CompanyTableRow>[] => [
  {
    columnLabel: 'Trading name',
    columnKey: 'tradingName',
    sortKey: 'byTradingName',
    type: RowDefinitionType.TEXT,
    isFrozen: true,
    width: {
      min: '240px',
      max: '240px',
    },
  },
  {
    columnLabel: 'logo',
    columnKey: 'logo',
    type: RowDefinitionType.IMAGE,
    width: {
      min: '150px',
      max: '150px',
    },
  },
  {
    columnLabel: 'id',
    columnKey: 'id',
    type: RowDefinitionType.TEXT,
    sortKey: 'byCompanyId',
    width: {
      min: '48px',
      max: '48px',
    },
  },
  {
    columnLabel: 'advisers',
    columnKey: 'advisorUserCount',
    type: RowDefinitionType.NUMBER,
    sortKey: 'byAdvisorUserCount',
    width: {
      min: '100px',
      max: '100px',
    },
  },
  {
    columnLabel: 'clients',
    columnKey: 'clientUserCount',
    type: RowDefinitionType.NUMBER,
    sortKey: 'byClientUserCount',
    width: {
      min: '100px',
      max: '100px',
    },
  },
  {
    columnLabel: 'mortgages',
    columnKey: 'mortgageCount',
    type: RowDefinitionType.NUMBER,
    sortKey: 'byMortgageCount',
    width: {
      min: '100px',
      max: '100px',
    },
  },
  {
    columnLabel: 'Active ref.codes',
    columnKey: 'activeReferralCount',
    type: RowDefinitionType.NUMBER,
    sortKey: 'byActiveReferralCount',
    width: {
      min: '100px',
      max: '100px',
    },
    isHidden: !hasCurrentUserRequiredPermissions([
      {
        permission: Permission.REFERRAL_READ,
      },
    ]),
  },
  {
    columnLabel: 'Network',
    columnKey: 'networkTradingName',
    type: RowDefinitionType.TEXT,
    sortKey: 'byNetworkTradingName',
    width: {
      min: '100px',
      max: '100px',
    },
    isHidden: !hasCurrentUserRequiredPermissions([
      {
        permission: Permission.COMPANY_READ,
        permissionLevel: ENUM_AdvisorUserPermissionLevel.ADVISOR_USER_PERMISSION_LEVEL_FULL,
      },
    ]),
  },
  {
    columnLabel: 'Client notifications',
    columnKey: 'notificationSettings',
    type: RowDefinitionType.NOTIFICATION_SETTINGS,
    width: {
      min: '125px',
      max: '125px',
    },
  },
];
