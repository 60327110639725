import { createSelector } from '@ngrx/store';
import { AppStateModel, AuthState } from '../../models/app-state.model';

export const selectAuthState = (state: AppStateModel): AuthState => state.auth;

export const selectToken = (state: AppStateModel): string => state.auth.token;

export const selectAuthUserUid = createSelector(selectAuthState, (state: AuthState): string => state?.userId);

export const selectAuthLoading = createSelector(selectAuthState, (state: AuthState) => (state != null ? state.loading : false));

export const selectAuthError = createSelector(selectAuthState, (state: AuthState) => state.errors);
