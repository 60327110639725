import {
  HTTP_AP_ListCases_ForApprovalRequiredTable_Request_Filter,
  HTTP_AP_ListCases_ForApprovalRequiredTable_Request_Sort,
  HTTP_AP_ListCases_ForClientDetail_Request_Filter,
  HTTP_AP_ListCases_ForClientDetail_Request_Sort,
  HTTP_AP_ListCases_ForClosedTable_Request_Filter,
  HTTP_AP_ListCases_ForClosedTable_Request_Sort,
  HTTP_AP_ListCases_ForLeadsTable_Request_Filter,
  HTTP_AP_ListCases_ForLeadsTable_Request_Sort,
  HTTP_AP_ListCases_ForOpportunitiesTable_Request_Filter,
  HTTP_AP_ListCases_ForOpportunitiesTable_Request_Sort,
  HTTP_AP_ListCases_ForPipelineTable_Request_Filter,
  HTTP_AP_ListCases_ForPipelineTable_Request_Sort,
} from '@api-new/caseservice';
import {
  HTTP_AP_ListAffiliates_ForTable_Request_Filter,
  HTTP_AP_ListCompanies_ForTable_Request_Filter,
  HTTP_AP_ListCompanies_ForTable_Request_Sort,
  HTTP_AP_ListNetworks_ForTable_Response_Network,
} from '@api-new/companyservice';
import { HTTP_AP_ListMortgageOfferParserBatches_Request_Filter } from '@api-new/documentservice';
import { HTTP_AP_ListImports_Request_Filter, HTTP_AP_ListImports_Request_Sort } from '@api-new/importservice';
import {
  HTTP_AP_LenderGroup,
  HTTP_AP_ListLenderGroups_ForTable_Request_Filter,
  HTTP_AP_ListLenderGroups_ForTable_Request_Sort,
  HTTP_AP_ListLenders_ForTable_Request_Filter,
  HTTP_AP_ListLenders_ForTable_Request_Sort,
  HTTP_AP_ListMortgageProducts_ForTable_Request_Filter,
  HTTP_AP_ListMortgageProducts_ForTable_Request_Sort,
  HTTP_AP_ListMortgageProducts_ForTable_Response_MortgageProduct,
  HTTP_AP_ListMortgages_ForTable_Request_Filter,
  HTTP_AP_ListMortgages_ForTable_Request_Sort,
  HTTP_AP_ListPreCompletions_ForTable_Request_Filter,
  HTTP_AP_ListPreCompletions_ForTable_Request_Sort,
} from '@api-new/mortgageservice';
import {
  HTTP_AP_HPIOutcodeToRegionEntry,
  HTTP_AP_ListHPIOutcodeToRegionEntries_Request_Filter,
  HTTP_AP_ListHPIOutcodeToRegionEntries_Request_Sort,
  HTTP_AP_ListProperties_ForTable_Request_Filter,
  HTTP_AP_ListProperties_ForTable_Request_Sort,
  HTTP_AP_ListProperties_ForTable_Response_Property,
} from '@api-new/propertyservice';
import {
  HTTP_AP_ListAdvisorUsers_ForTable_Request_Filter,
  HTTP_AP_ListAdvisorUsers_ForTable_Request_Sort,
  HTTP_AP_ListReferrals_Request_Filter,
  HTTP_AP_ListReferrals_Request_Sort,
  HTTP_AP_ListTinyUrls_Request_Filter,
  HTTP_AP_ListTinyUrls_Request_Sort,
} from '@api-new/userservice';
import { AffiliateTableRow } from '@app/platform/affiliate/models/affiliate-table.model';
import { createAction, props } from '@ngrx/store';
import { OpportunityTableRow } from '@platform/case/models/better-deal-table-row.model';
import { OpportunityApprovalRequiredTableRow } from '@platform/case/models/opportunity-approval-required-table-row.model';
import { ImportTableRow } from '@platform/import/models/import-table-row.model';
import { LenderTableRow } from '@platform/lender/utils/tableDefinition';
import { MortgageTableRow } from '@platform/mortgage/models/mortgage-table.model';
import { PreCompletionTableRow } from '@platform/mortgage/models/pre-completion-table.model';
import { ReferralCodeTableRow } from '@platform/referral-code/models/referral-code-table.model';
import { TinyUrlTableRow } from '@platform/tiny-url/models/tiny-url-table-row.model';
import { UserTableRow } from '@platform/user/models/user-table.model';
import { PaginationModel } from '@shared/components/table';
import { TablesEnum } from '@shared/store/table/tables.enum';

function createAddActions<Pagination, Sort, Filter, ErrorModel, TableRows = any[]>(tableName: string): any {
  const setPagination = createAction(
    `[${tableName}] set pagination`,
    props<{
      pagination: Pagination;
      tableName: TablesEnum;
    }>(),
  );
  const setSorting = createAction(
    `[${tableName}] set sorting`,
    props<{
      sort: Sort;
      tableName: TablesEnum;
    }>(),
  );
  const setFilter = createAction(
    `[${tableName}] set filter`,
    props<{
      filter: Filter;
      tableName: TablesEnum;
    }>(),
  );
  const setSelectedRows = createAction(
    `[${tableName}] set rows`,
    props<{
      entity: TableRows;
      tableName: TablesEnum;
    }>(),
  );
  const resetTableFilters = createAction(
    `[${tableName}] reset table filter`,
    props<{
      entity: Filter;
      tableName: TablesEnum;
    }>(),
  );

  return {
    setPagination,
    setSorting,
    setFilter,
    setSelectedRows,
    resetTableFilters,
  };
}

export const userTableActions = createAddActions<
  PaginationModel,
  HTTP_AP_ListAdvisorUsers_ForTable_Request_Sort,
  Partial<HTTP_AP_ListAdvisorUsers_ForTable_Request_Filter>,
  UserTableRow[]
>(TablesEnum.USER_TABLE);

export const outcodeToRegionTableActions = createAddActions<
  PaginationModel,
  HTTP_AP_ListHPIOutcodeToRegionEntries_Request_Sort,
  Partial<HTTP_AP_ListHPIOutcodeToRegionEntries_Request_Filter>,
  HTTP_AP_HPIOutcodeToRegionEntry[]
>(TablesEnum.OUTCODE_TO_REGION_TABLE);

export const mortgageTableActions = createAddActions<
  PaginationModel,
  HTTP_AP_ListMortgages_ForTable_Request_Sort,
  Partial<HTTP_AP_ListMortgages_ForTable_Request_Filter>,
  MortgageTableRow[]
>(TablesEnum.MORTGAGE_TABLE);

export const clientTableActions = createAddActions<
  PaginationModel,
  HTTP_AP_ListAdvisorUsers_ForTable_Request_Sort,
  Partial<HTTP_AP_ListAdvisorUsers_ForTable_Request_Filter>,
  UserTableRow[]
>(TablesEnum.CLIENT_TABLE);

export const propertyTableActions = createAddActions<
  PaginationModel,
  HTTP_AP_ListProperties_ForTable_Request_Sort,
  Partial<HTTP_AP_ListProperties_ForTable_Request_Filter>,
  HTTP_AP_ListProperties_ForTable_Response_Property[]
>(TablesEnum.PROPERTY_TABLE);

export const opportunityApproveRequiredTableActions = createAddActions<
  PaginationModel,
  HTTP_AP_ListCases_ForApprovalRequiredTable_Request_Sort,
  HTTP_AP_ListCases_ForApprovalRequiredTable_Request_Filter,
  OpportunityApprovalRequiredTableRow[]
>(TablesEnum.OPPORTUNITY_APPROVAL_REQUIRED_TABLE);

export const opportunityTableActions = createAddActions<
  PaginationModel,
  HTTP_AP_ListCases_ForOpportunitiesTable_Request_Sort,
  HTTP_AP_ListCases_ForOpportunitiesTable_Request_Filter,
  OpportunityTableRow[]
>(TablesEnum.OPPORTUNITY_TABLE);

export const mortgageLeadTableActions = createAddActions<
  PaginationModel,
  HTTP_AP_ListCases_ForLeadsTable_Request_Sort,
  HTTP_AP_ListCases_ForLeadsTable_Request_Filter,
  null
>(TablesEnum.MORTGAGE_PIPELINE_CASE_TABLE);

export const mortgagePipelineCaseTableActions = createAddActions<
  PaginationModel,
  HTTP_AP_ListCases_ForPipelineTable_Request_Sort,
  HTTP_AP_ListCases_ForPipelineTable_Request_Filter,
  null
>(TablesEnum.MORTGAGE_PIPELINE_CASE_TABLE);

export const mortgageClosedCaseTableActions = createAddActions<
  PaginationModel,
  HTTP_AP_ListCases_ForClosedTable_Request_Sort,
  HTTP_AP_ListCases_ForClosedTable_Request_Filter,
  null
>(TablesEnum.MORTGAGE_CLOSED_CASE_TABLE);

export const clientCaseTableActions = createAddActions<
  PaginationModel,
  HTTP_AP_ListCases_ForClientDetail_Request_Sort,
  HTTP_AP_ListCases_ForClientDetail_Request_Filter,
  null
>(TablesEnum.CLIENT_CASE_TABLE);

export const companyTableActions = createAddActions<
  PaginationModel,
  HTTP_AP_ListCompanies_ForTable_Request_Sort,
  HTTP_AP_ListCompanies_ForTable_Request_Filter,
  null
>(TablesEnum.COMPANY_TABLE);

export const importTableActions = createAddActions<
  PaginationModel,
  HTTP_AP_ListImports_Request_Sort,
  HTTP_AP_ListImports_Request_Filter,
  ImportTableRow[]
>(TablesEnum.IMPORT_TABLE);

export const lenderTableActions = createAddActions<
  PaginationModel,
  HTTP_AP_ListLenders_ForTable_Request_Sort,
  HTTP_AP_ListLenders_ForTable_Request_Filter,
  LenderTableRow[]
>(TablesEnum.LENDER_TABLE);

export const lenderGroupTableActions = createAddActions<
  PaginationModel,
  HTTP_AP_ListLenderGroups_ForTable_Request_Sort,
  HTTP_AP_ListLenderGroups_ForTable_Request_Filter,
  HTTP_AP_LenderGroup[]
>(TablesEnum.LENDER_GROUP_TABLE);

export const mortgageDocAiTableActions = createAddActions<
  PaginationModel,
  null,
  Partial<HTTP_AP_ListMortgageOfferParserBatches_Request_Filter>,
  null
>(TablesEnum.MORTGAGE_DOC_AI_TABLE);

export const tinyUrlsTableActions = createAddActions<
  PaginationModel,
  HTTP_AP_ListTinyUrls_Request_Sort,
  HTTP_AP_ListTinyUrls_Request_Filter,
  TinyUrlTableRow[]
>(TablesEnum.TINY_URLS_TABLE);

export const preCompletionTableActions = createAddActions<
  PaginationModel,
  HTTP_AP_ListPreCompletions_ForTable_Request_Sort,
  HTTP_AP_ListPreCompletions_ForTable_Request_Filter,
  PreCompletionTableRow[]
>(TablesEnum.TINY_URLS_TABLE);

export const referralCodesTableActions = createAddActions<
  PaginationModel,
  HTTP_AP_ListReferrals_Request_Sort,
  HTTP_AP_ListReferrals_Request_Filter,
  ReferralCodeTableRow[]
>(TablesEnum.REFERRAL_CODE_TABLE);

export const affiliateTableActions = createAddActions<
  PaginationModel,
  undefined,
  HTTP_AP_ListAffiliates_ForTable_Request_Filter,
  AffiliateTableRow[]
>(TablesEnum.AFFILIATE_TABLE);

export const mortgageProductTableActions = createAddActions<
  PaginationModel,
  HTTP_AP_ListMortgageProducts_ForTable_Request_Sort,
  HTTP_AP_ListMortgageProducts_ForTable_Request_Filter,
  HTTP_AP_ListMortgageProducts_ForTable_Response_MortgageProduct[]
>(TablesEnum.MORTGAGE_PRODUCT_TABLE);

export const networkTableActions = createAddActions<
  PaginationModel,
  undefined,
  undefined,
  HTTP_AP_ListNetworks_ForTable_Response_Network[]
>(TablesEnum.NETWORK_TABLE);
