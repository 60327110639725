import { ENUM_MortgageCaseClosedReason } from '@api-new/common';

export const ENUM_MortgageCaseClosedReasonMap = new Map([
  [ENUM_MortgageCaseClosedReason.MORTGAGE_CASE_CLOSED_REASON_UNSPECIFIED, 'Unspecified'],
  [ENUM_MortgageCaseClosedReason.MORTGAGE_CASE_CLOSED_REASON_CANCELLED_LOST, 'Cancelled - lost'],
  [ENUM_MortgageCaseClosedReason.MORTGAGE_CASE_CLOSED_REASON_CANCELLED_MORTGAGE_REPAID, 'Cancelled - mortgage repaid'],
  [ENUM_MortgageCaseClosedReason.MORTGAGE_CASE_CLOSED_REASON_CANCELLED_UNRESPONSIVE, 'Cancelled - unresponsive'],
  [ENUM_MortgageCaseClosedReason.MORTGAGE_CASE_CLOSED_REASON_CANCELLED_PROPERTY_SOLD, 'Cancelled - property sold'],
  [ENUM_MortgageCaseClosedReason.MORTGAGE_CASE_CLOSED_REASON_CANCELLED_OTHER, 'Cancelled - other'],
  [ENUM_MortgageCaseClosedReason.MORTGAGE_CASE_CLOSED_REASON_CANCELLED_NOT_TAKEN_UP, 'Cancelled - not taken up'],
  [ENUM_MortgageCaseClosedReason.MORTGAGE_CASE_CLOSED_REASON_CANCELLED_NOT_PROCEEDING_WITH, 'Cancelled - not proceeding with'],
  [ENUM_MortgageCaseClosedReason.MORTGAGE_CASE_CLOSED_REASON_COMPLETED, 'Completed'],
  [ENUM_MortgageCaseClosedReason.MORTGAGE_CASE_CLOSED_REASON_DEAL_EXPIRED, 'Deal expired'],
  [ENUM_MortgageCaseClosedReason.MORTGAGE_CASE_CLOSED_REASON_CANCELLED_STAYING_ON_EXISTING_DEAL, 'Cancelled - staying on existing deal'],
  [ENUM_MortgageCaseClosedReason.MORTGAGE_CASE_CLOSED_REASON_CANCELLED_UNABLE_TO_PLACE, 'Cancelled - unable to place'],
  [
    ENUM_MortgageCaseClosedReason.MORTGAGE_CASE_CLOSED_REASON_CANCELLED_LOST_TRANSFERRED_WITH_EXISTING_LENDER,
    'Cancelled - lost transferred with existing lender',
  ],
  [ENUM_MortgageCaseClosedReason.MORTGAGE_CASE_CLOSED_REASON_CANCELLED_LOST_DIRECT_TO_LENDER, 'Cancelled - lost direct to lender'],
  [ENUM_MortgageCaseClosedReason.MORTGAGE_CASE_CLOSED_REASON_CANCELLED_LOST_ANOTHER_BROKER, 'Cancelled - lost another broker'],
  [ENUM_MortgageCaseClosedReason.UNRECOGNIZED, 'Unrecognized'],
]);
