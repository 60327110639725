import { Pipe, PipeTransform } from '@angular/core';
import {
  UK_DEFAULT_DATETIME_FORMAT,
  UK_DEFAULT_FORMAT_LONG,
  UK_DEFAULT_FORMAT_LONG_CHAR,
  UK_DEFAULT_FORMAT_SHORT,
  UK_DEFAULT_TIME_FORMAT,
  UK_FORMAT_FULL_MONTH_AND_YEAR,
  UK_FORMAT_MONTH_AND_YEAR,
  UK_FORMAT_MONTH_AND_YEAR_SHORT,
} from '@shared-lib/constants/date-format';
import { momentify } from '@shared/utils';
import moment, { Moment } from 'moment';

@Pipe({
  name: 'dateFormat',
  pure: true,
  standalone: true,
})
export class DateFormatPipe implements PipeTransform {
  transform(
    dateInput: string | Moment,
    format:
      | 'short'
      | 'long'
      | 'longChar'
      | 'datetime'
      | 'monthAndYearShort'
      | 'fullMonthAndYear'
      | 'timeSince'
      | 'monthAndYear'
      | 'timeOnly' = 'long',
  ): string {
    dateInput = momentify(dateInput);
    if (dateInput == null || !dateInput.isValid()) {
      return '';
    }
    switch (format) {
      case 'short':
        return dateInput.format(UK_DEFAULT_FORMAT_SHORT);
      case 'longChar':
        return dateInput.format(UK_DEFAULT_FORMAT_LONG_CHAR);
      case 'datetime':
        return dateInput.format(UK_DEFAULT_DATETIME_FORMAT);
      case 'timeSince':
        return this.getTimeSince(dateInput);
      case 'timeOnly':
        return dateInput.format(UK_DEFAULT_TIME_FORMAT);
      case 'monthAndYear':
        return dateInput.format(UK_FORMAT_MONTH_AND_YEAR);
      case 'monthAndYearShort':
        return dateInput.format(UK_FORMAT_MONTH_AND_YEAR_SHORT);
      case 'fullMonthAndYear':
        return dateInput.format(UK_FORMAT_FULL_MONTH_AND_YEAR);
      default:
        return dateInput.format(UK_DEFAULT_FORMAT_LONG);
    }
  }

  private getTimeSince(dateInput: Moment): string {
    const now = moment();
    const daysDiff = now.diff(dateInput, 'days');
    if (daysDiff < 1) {
      const hourDiff = now.diff(dateInput, 'hours');
      if (hourDiff < 1) {
        const minDiff = now.diff(dateInput, 'minutes');
        if (minDiff < 2) {
          return '1 minute ago';
        }
        return `${now.diff(dateInput, 'minutes')} minutes ago`;
      }
      return `${hourDiff} hour${hourDiff <= 1 ? '' : 's'} ago`;
    }
    if (daysDiff < 2) {
      return 'Yesterday';
    }
    if (daysDiff < 7) {
      return `${daysDiff} days ago`;
    }
    const weeksDiff = now.diff(dateInput, 'weeks');
    if (weeksDiff < 4) {
      return `${weeksDiff} week${weeksDiff <= 1 ? '' : 's'} ago`;
    }
    const monthsDiff = now.diff(dateInput, 'months');
    if (monthsDiff <= 1) {
      return `1 month ago`;
    }
    if (monthsDiff <= 12) {
      return `${monthsDiff} months ago`;
    }
    const yearsDiff = now.diff(dateInput, 'year');
    if (yearsDiff <= 1) {
      return `> 1 year ago`;
    }
    if (yearsDiff > 3) {
      return `> 3 year ago`;
    }
    return `> 2 year ago`;
  }
}
