import { CurrencyPipe, PercentPipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ErrorHandler, LOCALE_ID, enableProdMode, importProvidersFrom } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { UrlSerializer, provideRouter, withPreloading } from '@angular/router';
import { routes } from '@app/app-routes';
import { AppComponent } from '@app/app.component';
import { metaReducers } from '@app/app.module';
import { SentryErrorHandler } from '@app/sentry-error-hadler';
import { environment } from '@environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { FullRouterStateSerializer, RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { Environment } from '@shared-lib/environment/environment';
import { ApplicationHttpClient, applicationHttpClientCreator } from '@shared-lib/services/application-http.service';
import { CustomPreloadingStrategy } from '@shared/custom-preloading-strategy';
import { AuthGuard } from '@shared/guards/auth.guard';
import { AuthErrorInterceptor } from '@shared/interceptors/auth-error.interceptor';
import { AuthInterceptor } from '@shared/interceptors/auth.interceptor';
import { StripEmptyParamsInterceptor } from '@shared/interceptors/strip-empty-params.interceptor';
import { loadGoogleMapsScript } from '@shared/scripts/google-maps-scripts';
import { CustomUrlSerialize } from '@shared/serializers/custom-url-serializer';
import { AssetAccessorService } from '@shared/services/asset-accessor.service';
import { EFFECTS, REDUCERS } from '@shared/store';
import { CustomSerializer } from '@shared/store/route-serializer';
import 'moment-duration-format';
import { CookieModule } from 'ngx-cookie';
import { MessageService } from 'primeng/api';
import { providePrimeNG } from 'primeng/config';
import { ToastModule } from 'primeng/toast';

if (environment.production) {
  enableProdMode();
}

// Load Google Maps script dynamically before bootstrapping the Angular app
loadGoogleMapsScript()
  .then(() => {
    bootstrapApplication(AppComponent, {
      providers: [
        importProvidersFrom(
          BrowserModule,
          StoreModule.forRoot(REDUCERS, {
            metaReducers,
            runtimeChecks: {
              strictStateImmutability: false,
              strictActionImmutability: false,
            },
          }),
          StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
          }),
          EffectsModule.forRoot(EFFECTS),
          StoreRouterConnectingModule.forRoot({ serializer: FullRouterStateSerializer }),
          CookieModule.withOptions(),
          GoogleMapsModule,
          ToastModule,
        ),
        {
          provide: ApplicationHttpClient,
          useFactory: applicationHttpClientCreator,
          deps: [HttpClient, Environment],
        },
        {
          provide: Environment,
          useValue: environment,
        },
        CurrencyPipe,
        {
          provide: ErrorHandler,
          useClass: SentryErrorHandler,
        },
        {
          provide: RouterStateSerializer,
          useClass: CustomSerializer,
        },
        PercentPipe,
        AssetAccessorService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthErrorInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: StripEmptyParamsInterceptor,
          multi: true,
        },
        {
          provide: UrlSerializer,
          useClass: CustomUrlSerialize,
        },
        AuthGuard,
        CustomPreloadingStrategy,
        { provide: LOCALE_ID, useValue: 'en-GB' },
        MessageService,
        // PrimeNg
        provideAnimationsAsync(),
        providePrimeNG({
          theme: {
            options: {
              cssLayer: {
                name: 'primeng',
              },
              darkModeSelector: false,
            },
          },
        }),
        // Angular
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi()),
        provideRouter(routes, withPreloading(CustomPreloadingStrategy)),
      ],
    }).catch((err) => console.error(err));
  })
  .catch((error) => {
    console.error('Error loading Google Maps script:', error);
  });
