import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  HostListener,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { IconComponent } from '@shared-lib/components/icon/icon.component';
import { NgRepeatDirective } from '@shared-lib/directives/ngRepeat.directive';
import { ToastAction, ToastService, ToastSeverity } from '@shared-lib/services/toast.service';
import { isEqual } from '@shared-lib/utils/isEqual';
import { PaginationModel, RowDefinitionType, SortType, TableRowDefinitionModel, TableSortingModel } from '@shared/components/table';
import { TableErrorComponent } from '@shared/components/table-error/table-error.component';
import { PaginatorComponent } from '@shared/components/table/paginator/paginator.component';
import { SortIconComponent } from '@shared/components/table/sort-icon/sort-icon.component';
import { ErrorModel } from '@shared/models/app-state.model';
import { TableSortOrderToNumberPipe } from '@shared/pipes/table-sort-order-to-number.pipe';
import { SharedModule } from '@shared/shared.module';
import { TOAST_MESSAGE } from '@shared/utils';
import { MenuItem, SortEvent } from 'primeng/api';
import { Menu, MenuModule } from 'primeng/menu';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { SkeletonModule } from 'primeng/skeleton';
import { Table, TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { TableCellComponent } from './components/table-cell.component';

const ACTION_MENU_BUTTON_CLASS = 'action-menu-button';

@Component({
  selector: 'ap-common-table',
  imports: [
    CommonModule,
    TableModule,
    SkeletonModule,
    SharedModule,
    MenuModule,
    TooltipModule,
    PaginatorComponent,
    SortIconComponent,
    NgRepeatDirective,
    MessagesModule,
    MessageModule,
    TableErrorComponent,
    TableSortOrderToNumberPipe,
    IconComponent,
    TableCellComponent,
  ],
  templateUrl: './common-table.component.html',
  styleUrls: ['./common-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonTableComponent<T> implements AfterViewInit {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  @ContentChild(TemplateRef) customRowRef: TemplateRef<any>;
  @ViewChild('actionMenu') actionMenu: Menu;
  @ViewChild('table') table: Table;

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.actionMenu?.hide();
    }
  }

  @HostListener('window:resize')
  handleWindowResize(): void {
    this.isHorizontallyScrollable =
      this.getTableWrapperElement()?.firstElementChild.clientWidth > this.getTableWrapperElement()?.clientWidth;
  }

  @Input() rowData: T[];
  @Input() selectedRows: T[];

  @Input() errors: ErrorModel;
  @Input() rowDefinitions: TableRowDefinitionModel<T>[];
  @Input() bulkActionMenuItems: MenuItem[];
  @Input() rowActionMenuItems: MenuItem[];
  @Input() pagination: PaginationModel;
  @Input() dataKey: string;
  @Input() emptyMessage: string;
  @Input() loading: boolean;
  @Input() hasCustomRow: boolean;
  @Input() customRowActions: boolean;
  @Input() countLabel = 'entries';
  @Input() testSelector: string;
  @Input() set sort(value: TableSortingModel<any>) {
    this.latestSort = value;
  }

  @Output() rowActionClicked = new EventEmitter<T>();
  @Output() rowClicked = new EventEmitter<T>();
  @Output() rowsSelected = new EventEmitter<T[]>();
  @Output() paginationChange = new EventEmitter<PaginationModel>();
  @Output() sortChange = new EventEmitter<TableSortingModel<any>>();
  @Output() retryButtonClicked = new EventEmitter<void>();

  latestSort: TableSortingModel<any>;
  hoverOverRow: T;
  rowDefinitionType = RowDefinitionType;
  messages = [{ severity: 'error', summary: 'Error', detail: 'Message Content' }];

  _isHorizontallyScrollable = false;

  constructor(
    private readonly toastService: ToastService,
    private readonly cdRef: ChangeDetectorRef,
  ) {}

  ngAfterViewInit(): void {
    this.isHorizontallyScrollable =
      this.getTableWrapperElement()?.firstElementChild.clientWidth > this.getTableWrapperElement()?.clientWidth;

    this.getTableWrapperElement()?.addEventListener('scroll', () => {
      this.isHorizontallyScrollable =
        this.getTableWrapperElement().scrollLeft + this.getTableWrapperElement().clientWidth !==
        this.getTableWrapperElement().firstElementChild.clientWidth;
      this.cdRef.markForCheck();
    });
  }

  emitPaginate({ pageNumber, pageSize }: PaginationModel): void {
    this.paginationChange.emit({ pageNumber, pageSize });
    this.getTableWrapperElement().scrollTop = 0;
  }

  emitSort(event: SortEvent): void {
    const newSort = {
      sortKey: event.field,
      sortOrder: event.order === 1 ? SortType.SORT_TYPE_ASC : SortType.SORT_TYPE_DESC,
    };
    if (this.latestSort?.sortOrder === SortType.SORT_TYPE_DESC && event.order === 1 && this.latestSort?.sortKey === newSort.sortKey) {
      this.sortChange.emit(null);
      return;
    }
    if (!isEqual(this.latestSort, newSort)) {
      this.latestSort = newSort;
      this.sortChange.emit(this.latestSort);
    }
    this.getTableWrapperElement().scrollTop = 0;
  }

  emitRowsSelected(rows: T[]): void {
    this.rowsSelected.emit(rows);
  }

  emitRowClicked(mouseEvent: MouseEvent, row: T): void {
    if (!(mouseEvent.target as Element).className.includes(ACTION_MENU_BUTTON_CLASS)) {
      this.rowClicked.emit(row);
    }
  }

  emitFetchData(): void {
    this.retryButtonClicked.emit();
  }

  onHoverEnterRow(row: T): void {
    this.hoverOverRow = row;
  }

  onHoverLeaveRow(): void {
    this.hoverOverRow = null;
  }

  copyLink(event: MouseEvent, link: string): void {
    event.stopPropagation();
    navigator.clipboard
      .writeText(link)
      .then(() => this.toastService.showToast(ToastSeverity.success, TOAST_MESSAGE.SUCCESS[ToastAction.action_copy]('Link')))
      .catch(() => this.toastService.showToast(ToastSeverity.error, TOAST_MESSAGE.ERROR[ToastAction.action_copy]('Link')));
  }

  showMenu(row: T, event: MouseEvent): void {
    event.stopPropagation();
    this.rowActionClicked.emit(row);
    this.actionMenu.toggle(event);
  }

  emitUnselectAllRows(): void {
    this.rowsSelected.emit([]);
  }

  get bulkActionsEnabled(): boolean {
    return !!this.bulkActionMenuItems;
  }

  get rowActionsEnabled(): boolean {
    return !!this.rowActionMenuItems;
  }

  get isHorizontallyScrollable(): boolean {
    return this._isHorizontallyScrollable;
  }

  set isHorizontallyScrollable(isHorizontallyScrollable: boolean) {
    this._isHorizontallyScrollable = isHorizontallyScrollable;
  }

  get isVerticallyScrollable(): boolean {
    return this.getTableWrapperElement()?.clientHeight < this.getTableWrapperElement()?.firstElementChild.clientHeight;
  }

  private getTableWrapperElement(): HTMLElement {
    return this.table?.el.nativeElement.getElementsByClassName('p-datatable-table-container')[0];
  }
}
