import { Action, createReducer, on } from '@ngrx/store';
import { UserState } from '@shared/models/app-state.model';
import {
  activateUser,
  activateUserFailure,
  activateUserSuccess,
  getUserById,
  getUserFailure,
  getUserSuccess,
  resetUserPassword,
  resetUserPasswordFailure,
  resetUserPasswordSuccess,
  sendResetPasswordLink,
  sendResetPasswordLinkFailure,
  sendResetPasswordLinkSuccess,
  setRedirectUrlAfterSignIn,
} from './user.action';

const initialState: UserState = {
  errors: null,
  loading: false,
  loaded: false,
  metadata: {
    redirectUrlAfterSignIn: null,
    companyId: null,
    passwordReset: false,
  },
};

const reducer = createReducer(
  initialState,

  on(getUserById, activateUser, sendResetPasswordLink, resetUserPassword, (state) => ({
    ...state,
    loading: true,
  })),

  on(getUserSuccess, (state, { user }) => ({
    ...state,
    loading: false,
    loaded: true,
    entity: user,
    metadata: {
      ...state.metadata,
    },
  })),

  on(getUserFailure, activateUserFailure, sendResetPasswordLinkFailure, resetUserPasswordFailure, (state, { error }) => ({
    ...state,
    loading: false,
    errors: error,
  })),

  on(activateUserSuccess, sendResetPasswordLinkSuccess, (state) => ({
    ...state,
    loading: false,
  })),

  on(resetUserPasswordSuccess, (state) => ({
    ...state,
    loading: false,
    metadata: {
      ...state.metadata,
      passwordReset: true,
    },
  })),

  on(setRedirectUrlAfterSignIn, (state, { redirectUrlAfterSignIn }) => ({
    ...state,
    metadata: {
      ...state.metadata,
      redirectUrlAfterSignIn,
    },
  })),
);

export function userReducer(state: UserState = initialState, action: Action): UserState {
  return reducer(state, action);
}
