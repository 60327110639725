import { ENUM_ImportType } from '@api-new/common';

export const ENUM_ImportTypeMap = new Map([
  [ENUM_ImportType.IMPORT_TYPE_UNSPECIFIED, 'Unspecified'],
  [ENUM_ImportType.IMPORT_TYPE_CLIENT_USER_IMPORT, 'Client Import'],
  [ENUM_ImportType.IMPORT_TYPE_DATA_IMPORT, 'Data import'],
  [ENUM_ImportType.IMPORT_TYPE_MORTGAGE_ADJUSTMENT, 'Mortgage adjustment'],
  [ENUM_ImportType.IMPORT_TYPE_MORTGAGE_REWRITE, 'Mortgage rewrite'],
  [ENUM_ImportType.IMPORT_TYPE_MORTGAGE_CLOSURE, 'Mortgage closure'],
  [ENUM_ImportType.IMPORT_TYPE_MORTGAGE_PRE_COMPLETION, 'Mortgage pre-completion'],
  [ENUM_ImportType.UNRECOGNIZED, 'UNRECOGNIZED'],
]);
