<!-- Custom Cell -->
<ng-content select="[custom-cell-content]" />

<!-- Show defaults -->
@if (!customCellContent) {
  @switch (rowDefinition.type) {
    @case (ROW_DEFINITION_TYPE.TEXT) {
      <span [pTooltip]="row[rowDefinition.columnKey]">
        {{ row[rowDefinition.columnKey] || "-" }}
      </span>
    }
    @case (ROW_DEFINITION_TYPE.FULLNAME_SHORT) {
      <span [pTooltip]="row[rowDefinition.columnKey]">
        {{ (row[rowDefinition.columnKey] | nameFormat: "short") || "-" }}
      </span>
    }
    @case (ROW_DEFINITION_TYPE.FULLNAME_INITIALS) {
      <span [pTooltip]="row[rowDefinition.columnKey]">
        {{ (row[rowDefinition.columnKey] | nameFormat: "initials") || "-" }}
      </span>
    }
    @case (ROW_DEFINITION_TYPE.NUMBER) {
      <span>
        {{ row[rowDefinition.columnKey] || "-" }}
      </span>
    }
    @case (ROW_DEFINITION_TYPE.BOOLEAN) {
      <span>
        {{ (row[rowDefinition.columnKey] | booleanToYesOrNo) || "-" }}
      </span>
    }
    @case (ROW_DEFINITION_TYPE.PERCENT) {
      <span>
        {{ (row[rowDefinition.columnKey] | percent: "1.2-2") || "-" }}
      </span>
    }
    @case (ROW_DEFINITION_TYPE.CURRENCY) {
      <span>
        {{ (row[rowDefinition.columnKey] | currency: "GBP" : "symbol" : "1.0-0") || "-" }}
      </span>
    }
    @case (ROW_DEFINITION_TYPE.ADDRESS) {
      <span>
        {{ (row[rowDefinition.columnKey]?.lines | join: ",") || "-" }}
      </span>
    }
    @case (ROW_DEFINITION_TYPE.BINARY_STATUS) {
      <div>
        <ap-binary-status [status]="row[rowDefinition.columnKey]" />
      </div>
    }
    @case (ROW_DEFINITION_TYPE.COPY) {
      <div>
        <div class="copy-link" (click)="copyLink($event, row[rowDefinition.columnKey].link)">
          <span>{{ row[rowDefinition.columnKey].label }}</span>
          <shared-icon icon="copy" class="ap-icon-copy" />
        </div>
      </div>
    }
    @case (ROW_DEFINITION_TYPE.IMAGE) {
      <div class="logo-container">
        <img [src]="row[rowDefinition.columnKey]" class="logo" />
      </div>
    }
    @case (ROW_DEFINITION_TYPE.LABELED_IMG) {
      <div class="labeled-logo-container">
        <img [src]="row[rowDefinition.columnKey].src" class="logo" />
        <div class="labeled-logo-label">{{ row[rowDefinition.columnKey].label }}</div>
      </div>
    }
    @case (ROW_DEFINITION_TYPE.DATE) {
      <div>
        {{ (row[rowDefinition.columnKey] | dateFormat: "long") ?? "Never" }}
      </div>
    }
    @case (ROW_DEFINITION_TYPE.DATE_WITH_TIME) {
      <div>
        {{ (row[rowDefinition.columnKey] | dateFormat: "datetime") ?? "Never" }}
      </div>
    }
    @case (ROW_DEFINITION_TYPE.PROPERTY_INVESTMENT_TYPE) {
      <div>
        {{ ENUM_PropertyInvestmentTypeRecord[row[rowDefinition.columnKey]].label }}
      </div>
    }
    @case (ROW_DEFINITION_TYPE.DURATION_MONTHS) {
      <div>
        {{ (row[rowDefinition.columnKey] | formattedDurationMonths) ?? "-" }}
      </div>
    }
    @case (ROW_DEFINITION_TYPE.DATE_TIME_SINCE) {
      <div>
        <div class="date-since" [pTooltip]="row[rowDefinition.columnKey] | dateFormat: 'datetime'">
          {{ (row[rowDefinition.columnKey] | dateFormat: "timeSince") ?? "Never" }}
        </div>
      </div>
    }
    @case (ROW_DEFINITION_TYPE.NOTIFICATION_SETTINGS) {
      <ap-notification-settings-tag
        value="HR"
        [color]="TagColor.TURQUOISE"
        pTooltip="Home Reports"
        tooltipPosition="bottom"
        [disabled]="!row.notificationSettings.homeReportNotificationsEnabled" />
      <ap-notification-settings-tag
        value="BDA"
        [color]="TagColor.ORANGE"
        pTooltip="Better Deal Alerts"
        tooltipPosition="bottom"
        [disabled]="!row.notificationSettings.betterDealAlertNotificationsEnabled" />
      <ap-notification-settings-tag
        value="MU"
        [color]="TagColor.BLUE"
        pTooltip="Mortgage Update"
        tooltipPosition="bottom"
        [disabled]="!row.notificationSettings.mortgageUpdateNotificationsEnabled" />
    }

    @default {
      <ng-container class="common-table-row-item-label">
        {{ row[rowDefinition.columnKey] ?? "-" }}
      </ng-container>
    }
  }
}
