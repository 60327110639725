import { HTTP_AP_Company } from '@api-new/companyservice';
import { createAction, props } from '@ngrx/store';
import { CreateCompanyFormRawValues } from '@platform/company/pages/company-create/utils/buildCreateCompanyForm';
import { ErrorModel } from '@shared/models/app-state.model';

export const getCompanyById = createAction('[COMPANY] Get Company By ID', props<{ id: string }>());

export const getCompanyByIdSuccess = createAction('[COMPANY] Get Company By ID Success', props<{ company: HTTP_AP_Company }>());

export const getCompanyByIdFailure = createAction('[COMPANY] Get Company By ID Failure', props<{ error: ErrorModel }>());

export const createCompany = createAction('[COMPANY] Create Company', props<{ company: CreateCompanyFormRawValues }>());

export const createCompanySuccess = createAction('[COMPANY] Create Company Success', props<{ company: HTTP_AP_Company }>());

export const createCompanyFailure = createAction('[COMPANY] Create Company Failure', props<{ error: ErrorModel }>());
