import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ENUM_CompanyType } from '@api-new/common';
import { CompanyApiService, HTTP_AP_Company, HTTP_AP_CreateCompany_Request } from '@api-new/companyservice';
import { HTTP_X_GenerateUploadUrl_Response } from '@api-new/documentservice';
import { RoutePaths } from '@app/app.utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CreateCompanyFormRawValues } from '@platform/company/pages/company-create/utils/buildCreateCompanyForm';
import { FileUploadService } from '@shared-lib/services/file-upload.service';
import { ToastAction, ToastService, ToastSeverity } from '@shared-lib/services/toast.service';
import { PlatformType } from '@shared/enums';
import { ErrorModel } from '@shared/models/app-state.model';
import { setPlatformType } from '@shared/store/platform/platform.actions';
import { TOAST_MESSAGE } from '@shared/utils';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { createCompanyFormToHTTPRequestAdapter } from '../adapters/createCompanyFormToHTTPRequest.adapter';
import * as CompanyActions from './company.action';

@Injectable()
export class CompanyEffect {
  constructor(
    private readonly actions$: Actions,
    private readonly toastService: ToastService,
    private readonly router: Router,
    private readonly fileUploadService: FileUploadService,
    private readonly companyService: CompanyApiService,
  ) {}

  getCompanyById = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompanyActions.getCompanyById),
      filter(({ id }) => id != null),
      switchMap(({ id }) =>
        this.companyService.HTTP_AP_GetCompany({ companyId: id }).pipe(
          map((company) => CompanyActions.getCompanyByIdSuccess({ company })),
          catchError((error: ErrorModel) => {
            this.toastService.showToast(ToastSeverity.error, TOAST_MESSAGE.ERROR.getting_data);
            return of(CompanyActions.getCompanyByIdFailure({ error }));
          }),
        ),
      ),
    );
  });

  handleGetCompanyByIdSuccess = createEffect(
    (): Observable<any> =>
      this.actions$.pipe(
        ofType(CompanyActions.getCompanyByIdSuccess),
        map(({ company }) => {
          let platformType: PlatformType;
          switch (company.companyType) {
            case ENUM_CompanyType.COMPANY_TYPE_BROKER_COMPANY:
            case ENUM_CompanyType.COMPANY_TYPE_AGGREGATOR_COMPANY:
              platformType = PlatformType.ADVISER;
              break;

            case ENUM_CompanyType.COMPANY_TYPE_LENDER_COMPANY:
              platformType = PlatformType.LENDER;
              break;
          }

          return setPlatformType({ platformType });
        }),
      ),
  );

  createCompany = createEffect(
    (): Observable<any> =>
      this.actions$.pipe(
        ofType(CompanyActions.createCompany),
        mergeMap(({ company }) => forkJoin([this.fileUploadService.requestFileUpload(company.newLogo), of(company)])), // upload logo image
        map(([uploadResponse, companyWithoutLogo]: [HTTP_X_GenerateUploadUrl_Response, CreateCompanyFormRawValues]) =>
          createCompanyFormToHTTPRequestAdapter(companyWithoutLogo, uploadResponse.uploadUrl),
        ),
        switchMap((dataToUpdate: HTTP_AP_CreateCompany_Request) =>
          this.companyService.HTTP_AP_CreateCompany(dataToUpdate).pipe(
            map((createdCompany: HTTP_AP_Company) => {
              this.toastService.showToast(ToastSeverity.success, TOAST_MESSAGE.SUCCESS[ToastAction.action_create]('Company'));
              void this.router.navigate([RoutePaths.Platform, RoutePaths.Companies, createdCompany.id]);
              // Loading all company list one more time after successful upload
              return CompanyActions.createCompanySuccess({ company: createdCompany });
            }),
            catchError((error: ErrorModel) => {
              this.toastService.showToast(ToastSeverity.error, TOAST_MESSAGE.ERROR[ToastAction.action_create]('Company'));
              return of(CompanyActions.createCompanyFailure({ error }));
            }),
          ),
        ),
      ),
  );
}
