// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// source: shared/api/importservice/ENUM_CompanyIntegrationImportColumnsType.proto

export enum ENUM_CompanyIntegrationImportColumnsType {
  COMPANY_INTEGRATION_IMPORT_COLUMNS_TYPE_UNSPECIFIED = 'COMPANY_INTEGRATION_IMPORT_COLUMNS_TYPE_UNSPECIFIED',
  COMPANY_INTEGRATION_IMPORT_COLUMNS_TYPE_CLEARSCORE = 'COMPANY_INTEGRATION_IMPORT_COLUMNS_TYPE_CLEARSCORE',
  COMPANY_INTEGRATION_IMPORT_COLUMNS_TYPE_EXPERIAN = 'COMPANY_INTEGRATION_IMPORT_COLUMNS_TYPE_EXPERIAN',
  COMPANY_INTEGRATION_IMPORT_COLUMNS_TYPE_FLUENT_EXISTING_CUSTOMER = 'COMPANY_INTEGRATION_IMPORT_COLUMNS_TYPE_FLUENT_EXISTING_CUSTOMER',
  COMPANY_INTEGRATION_IMPORT_COLUMNS_TYPE_MAB = 'COMPANY_INTEGRATION_IMPORT_COLUMNS_TYPE_MAB',
  UNRECOGNIZED = 'UNRECOGNIZED',
}
