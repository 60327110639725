import { DATA_Address, DATA_AddressLines } from '@api-new/common';
import { AddressData, AddressFormValuesModel } from '../models/address-form.models';

export const manualAddressToDATA_AddressAdapter = (formAddressData: AddressFormValuesModel): DATA_Address => ({
  uprn: undefined,
  buildingName: formAddressData.buildingName || '',
  buildingNumber: formAddressData.buildingNumber || '',
  city: formAddressData.city || '',
  companyName: formAddressData.companyName || '',
  postcode: formAddressData.postcode || '',
  street: formAddressData.street || '',
  dependentStreet: '',
  subBuildingName: '',
});

export const buildLinesForAddress = (addressData: DATA_Address): DATA_AddressLines => {
  return {
    lines: [
      `${addressData?.buildingName || ''} ${addressData?.companyName || ''}`.trim(),
      `${addressData?.buildingNumber || ''} ${addressData?.street}`.trim(),
      `${addressData?.city}`,
      `${addressData?.postcode}`,
    ].filter((l) => l),
  };
};

const handlePickedAddress = (pickedAddress: AddressData | string | null): AddressData | null => {
  if (!pickedAddress || typeof pickedAddress == 'string') return null;
  return pickedAddress;
};

const handleManualAddress = (formAddressData: AddressFormValuesModel): AddressData | null => {
  const dataAddress = manualAddressToDATA_AddressAdapter(formAddressData);
  const addressLines = buildLinesForAddress(dataAddress);

  return {
    addressLines,
    address: dataAddress,
    coordinates: undefined,
    details: undefined,
  };
};

export const addressFormToAddressDataAdapter = (formAddressData: AddressFormValuesModel | null): AddressData | null => {
  if (formAddressData === null) return null;

  if (formAddressData.isManual) return handleManualAddress(formAddressData);
  return handlePickedAddress(formAddressData.pickedAddress);
};
