export const getUserIdFromToken = (token: string): string => {
  if (!token) return null;

  try {
    const tokenData = JSON.parse(atob(token.split('.')[1]));
    return tokenData?.sub || null;
  } catch (error) {
    return null;
  }
};
