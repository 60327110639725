import { ToastAction } from '@shared-lib/services/toast.service';

export const TOAST_MESSAGE = {
  // FIXME: move to apiErrorCodeMap
  LOGIN: {
    error_wrong_credentials: 'Missing email or password',
    error_initialize: 'Failed to initialize login with provider',
    error_invalid_provider: 'Invalid SSO provider',
  },

  ERROR: {
    unauthorized: 'You are unauthorized. Please log in to continue',
    general: 'Something went wrong',
    getting_data: 'Error occurred in process of getting data',
    uploading_file: 'File failed to upload',
    processing_files: 'Files failed to process',
    accessing_client_portal: 'Failed to access Client Portal',
    resuming_mortgage: 'Mortgage failed to resume',
    exporting_referral_codes: 'Referral codes fail to export',

    [ToastAction.action_create]: (entityName: string): string => `${entityName} failed to create`,
    [ToastAction.action_update]: (entityName: string): string => `${entityName} failed to update`,
    [ToastAction.action_delete]: (entityName: string): string => `${entityName} failed to delete`,
    [ToastAction.action_activate]: (entityName: string): string => `${entityName} failed to activate`,
    [ToastAction.action_deactivate]: (entityName: string): string => `${entityName} failed to deactivate`,
    [ToastAction.action_reassign]: (entityName: string): string => `${entityName} failed to reassign`,
    [ToastAction.action_close]: (entityName: string): string => `${entityName} failed to close`,
    [ToastAction.action_copy]: (entityName: string): string => `We were unable to copy the ${entityName} to your clipboard`,
    [ToastAction.action_email]: (entityName: string): string => `We were unable to send the ${entityName} email notification.`,
  },
  SUCCESS: {
    resuming_mortgage: 'Mortgage resumed successfully!',
    adding_document: 'Document added successfully!',

    [ToastAction.action_create]: (entityName: string): string => `${entityName} created successfully!`,
    [ToastAction.action_update]: (entityName: string): string => `${entityName} updated successfully!`,
    [ToastAction.action_delete]: (entityName: string): string => `${entityName} deleted successfully!`,
    [ToastAction.action_activate]: (entityName: string): string => `${entityName} activated successfully!`,
    [ToastAction.action_deactivate]: (entityName: string): string => `${entityName} deactivated successfully!`,
    [ToastAction.action_reassign]: (entityName: string): string => `${entityName} reassigned successfully!`,
    [ToastAction.action_close]: (entityName: string): string => `${entityName} closed successfully!`,
    [ToastAction.action_copy]: (entityName: string): string => `${entityName} copied to your clipboard`,
    [ToastAction.action_email]: (entityName: string): string => `${entityName} email notification has been sent successfully!`,
  },
  INFO: {
    email_data_link: 'We will send you a link to the data via email as soon as it is ready.',
    processing_files_started: 'Processing of the files has started',
    closed_mortgage_detail: 'Detail page of closed mortgages is currently unavailable',
    hr_emails_sent: 'Home report mails have been sent.',
  },
  WARNING: {
    mortgage_doc_ai_in_progress: 'A MortgageDocAI Batch is currently being processed.',
  },
};
