import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { TagModule } from 'primeng/tag';

export enum TagColor {
  BLUE = 'blue-background',
  TURQUOISE = 'turquoise-background',
  ORANGE = 'orange-background',
}

@Component({
  selector: 'ap-notification-settings-tag',
  imports: [TagModule],
  templateUrl: './notification-settings-tag.component.html',
  styleUrls: ['./notification-settings-tag.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationSettingsTagComponent {
  @Input() value?: string = '';
  @Input() disabled?: boolean = false;
  @Input() backgroundHexColour?: string = null;
  @Input() color: TagColor = null;
}
