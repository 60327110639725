import { createAction, props } from '@ngrx/store';
import { SignInApiErrorCode } from '@shared/enums';
import { ErrorModel } from '../../models/app-state.model';

export const loginAction = createAction('LoginAction', props<{ email: string; password: string }>());

export const loginWithTokenAction = createAction('LoginWithTokenAction', props<{ customToken: string }>());

export const loginWithTokenFromSSOAction = createAction('LoginWithTokenFromSSOAction', props<{ customToken: string }>());

export const loginSuccessAction = createAction('LoginSuccessAction', props<{ token: string; uid: string }>());

export const loginFailureAction = createAction('LoginFailureAction', props<{ error: ErrorModel }>());

export const resetPasswordResetDoneAction = createAction('ResetPasswordResetDoneAction');

export const logoutAction = createAction('LogoutAction');

export const resetStoreAction = createAction('ResetStoreAction');

export const authError = createAction('[AUTH] AuthError', props<{ authErrorCode: SignInApiErrorCode }>());
