import { ENUM_MortgageExpiryType } from '@api-new/common';

export const ENUM_MortgageExpiryTypeMap = new Map([
  [ENUM_MortgageExpiryType.MORTGAGE_EXPIRY_TYPE_UNSPECIFIED, ''],
  [ENUM_MortgageExpiryType.MORTGAGE_EXPIRY_TYPE_EARLY, 'Early'],
  [ENUM_MortgageExpiryType.MORTGAGE_EXPIRY_TYPE_PRE_EXPIRING, 'Pre-Expiring'],
  [ENUM_MortgageExpiryType.MORTGAGE_EXPIRY_TYPE_EXPIRING, 'Expiring'],
  [ENUM_MortgageExpiryType.MORTGAGE_EXPIRY_TYPE_EXPIRED, 'Expired'],
  [ENUM_MortgageExpiryType.UNRECOGNIZED, ''],
]);
