import { Injectable, inject } from '@angular/core';
import {
  AuthApiService,
  HTTP_AP_SignIn_WithProvider_Authorize_Response,
  HTTP_AP_SignIn_WithProvider_Initialize_Response,
} from '@api-new/authservice';
import { ENUM_AuthorizationProvider } from '@api-new/common';
import { Store } from '@ngrx/store';
import { LocalStorageKey, LoginProvider } from '@shared/enums';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { selectAuthUserUid } from '../store/auth/auth.selectors';
import { getUserById } from '../store/user/user.action';
import { getUserIdFromToken } from '../utils/tokenUtils';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private readonly ACCESS_TOKEN = 'accessToken';
  private readonly store = inject(Store);
  private readonly authApiService = inject(AuthApiService);
  private readonly localStorageService = inject(LocalStorageService);

  initAuthData(): void {
    const token = localStorage.getItem(this.ACCESS_TOKEN);
    if (!token) return null;

    const userId = getUserIdFromToken(token);
    this.store.dispatch(getUserById({ id: userId }));
  }

  initLoginWithProvider(loginProvider: LoginProvider): Observable<HTTP_AP_SignIn_WithProvider_Initialize_Response> {
    let authorizationProvider: ENUM_AuthorizationProvider;
    switch (loginProvider) {
      case LoginProvider.GOOGLE:
        authorizationProvider = ENUM_AuthorizationProvider.AUTHORIZATION_PROVIDER_GOOGLE;
        break;
      case LoginProvider.MAB:
        authorizationProvider = ENUM_AuthorizationProvider.AUTHORIZATION_PROVIDER_MAB;
        break;
      default:
        throw new Error('Invalid SSO provider');
    }
    return this.authApiService.HTTP_AP_SignIn_WithProvider_Initialize({ authorizationProvider });
  }

  authenticateLoginWithProvider(
    loginProvider: LoginProvider,
    authorizationResponseUrl: string,
  ): Observable<HTTP_AP_SignIn_WithProvider_Authorize_Response> {
    let authorizationProvider: ENUM_AuthorizationProvider;
    switch (loginProvider) {
      case LoginProvider.GOOGLE:
        authorizationProvider = ENUM_AuthorizationProvider.AUTHORIZATION_PROVIDER_GOOGLE;
        break;
      case LoginProvider.MAB:
        authorizationProvider = ENUM_AuthorizationProvider.AUTHORIZATION_PROVIDER_MAB;
        break;
      default:
        throw new Error('Invalid SSO provider');
    }
    return this.authApiService.HTTP_AP_SignIn_WithProvider_Authorize({ authorizationProvider, authorizationResponseUrl });
  }

  logout(): void {
    localStorage.setItem(this.ACCESS_TOKEN, '');
  }

  getToken(): string {
    return localStorage.getItem(this.ACCESS_TOKEN) || null;
  }

  authUser(): Observable<string> {
    return this.store.select(selectAuthUserUid);
  }

  userId(): Observable<string> {
    return this.authUser().pipe(map((userId) => userId || null));
  }

  setRedirectUrl(redirectUrl: string): void {
    this.localStorageService.setItem(LocalStorageKey.LOGIN_REDIRECT_URL, redirectUrl);
  }

  getRedirectUrl(): string {
    return this.localStorageService.getItem(LocalStorageKey.LOGIN_REDIRECT_URL);
  }

  clearRedirectUrl(): void {
    this.localStorageService.removeItem(LocalStorageKey.LOGIN_REDIRECT_URL);
  }

  setAccessToken(token: string): void {
    localStorage.setItem(this.ACCESS_TOKEN, token);
  }
}
