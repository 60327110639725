// PATH CONSTANTS
export const DASHLY_COMPANY_LOGO = 'assets/logos/toolbar-logo.svg';
export const IMAGE_PLACEHOLDER = 'assets/images/lender_logo_placeholder.svg';
export const PROPERTY_IMAGE_PLACEHOLDER = 'assets/images/property-no-image.png';
export const PROFILE_IMAGE_PLACEHOLDER = 'assets/images/profile-placeholder-image.png';

export const DASHLY_COMPANY_ID = '1';
export const BANK_OF_ENGLAND_NAME = 'Bank of England';
export const PROFILE_NAME_PLACEHOLDER = "Name isn't set";
export const COMPANY_LOGO_COOKIE = 'adviserCompanyLogo';

export const DEFAULT_COMPARISON_PERIOD = 24; // months
export const HOURS_THRESHOLD_MORNING = 12;
export const HOURS_THRESHOLD_AFTERNOON = 19;
export const ANIMATION_DELAY = 2000;
export const MIN_COMPARISON_PERIOD = 6;
export const MAX_COMPARISON_PERIOD = 60;
export const MIN_ERC_PERCENTAGE_RATE_NEW = 0.01;
export const MAX_ERC_PERCENTAGE_RATE_NEW = 10;
export const MAX_ERC_PERCENTAGE_RATE = 0.1;
export const MIN_ERC_FIXED_RATE = 1;
export const MAX_FIRST_NAME_LENGTH = 40;
export const MAX_LAST_NAME_LENGTH = 60;
export const MIN_MORTGAGE_TERM = 60;
export const MAX_MORTGAGE_TERM = 480;
export const MIN_INITIAL_AMOUNT = 0;
export const MAX_PASSWORD_LENGTH = 128;
export const MIN_PASSWORD_LENGTH = 8;
export const MIN_BIRTH_YEAR = 1900;
export const MAX_ADDITIONAL_YEARS = 5;
export const MIN_DAYS_IN_MONTH = 31;
export const MONTHS_IN_YEAR = 12;
export const MAX_YEARS_INPUT = 3;
export const MIN_CLIENT_AGE_MONTHS = 216; // 18 years in months
