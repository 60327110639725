// DATE FORMAT CONSTANTS
export const UK_DEFAULT_FORMAT_LONG = 'DD/MM/YYYY';
export const MONTH_ONLY_FORMAT = 'MMMM';
export const UK_DEFAULT_FORMAT_SHORT = 'DD/MM/YY';
export const UK_DEFAULT_FORMAT_DATE_MONTH_YEAR = 'dd/mm/yy';
export const UK_DEFAULT_FORMAT_LONG_CHAR = 'DD MMMM YYYY';
export const UK_FORMAT_MONTH_AND_YEAR = 'MM/YYYY';
export const UK_FORMAT_FULL_MONTH_AND_YEAR = 'MMMM YYYY';
export const UK_FORMAT_MONTH_AND_YEAR_SHORT = 'MM/YY';
export const UK_DEFAULT_DATETIME_FORMAT = `h:mma (${UK_DEFAULT_FORMAT_LONG})`;
export const UK_DEFAULT_TIME_FORMAT = `h:mma`;
